import { NgModule } from "@angular/core";
import { PaymentComponent } from "./payment.component";
import { RouterModule } from "@angular/router";
import { PaymentRoutingModule } from "./payment-routing.module";

@NgModule({
    declarations: [PaymentComponent],
    imports: [PaymentRoutingModule],
    exports: [RouterModule],
})
export class PaymentModule {}
