import { NgModule } from "@angular/core";
import { PaymentComponent } from "./payment.component";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
    {
        path: "",
        component: PaymentComponent,
        children: [
            {
                path: "success",
                loadChildren: () =>
                    import("../payments/payment.module").then(
                        (m) => m.PaymentModule,
                    ),
            },
            {
                path: "failure",
                loadChildren: () =>
                    import("../payments/payment.module").then(
                        (m) => m.PaymentModule,
                    ),
            },
        ],
    },
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PaymentRoutingModule {}
