import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

const MESSAGE = {
    success: "Transactie is succesvol afgerond",
    failure: "Transactie is mislukt",
};

@Component({
    selector: "ge-payment-result",
    template:
        '<div class="middle-box text-center animated fadeInDown"><h2 class="font-bold">{{ message }}</h2></div>',
    styleUrls: [],
})
export class PaymentComponent implements OnInit {
    private _message = "";

    constructor(private router: Router) {}

    get message() {
        return this._message;
    }

    ngOnInit() {
        const status = this.getTransactionStatus();
        this._message = MESSAGE[status];
    }

    private getTransactionStatus(): string {
        return this.router.url.substring(this.router.url.lastIndexOf("/") + 1);
    }
}
